/**=====================
  5.3 Header CSS Start
==========================**/
/**======Main Header css Start ======**/
.offcanvas,
.offcanvas-bookmark {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        filter: blur(5px) grayscale(100%);
      }
    }
  }
}
.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}
.toggle-sidebar {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  svg {
    width: $header-wrapper-nav-icon-size;
    height: $header-wrapper-nav-icon-size;
  }
  &:before {
    content: "";
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: rgba($primary-color, 0.1);
    border-radius: 100%;
    left: -11px;
    z-index: -2;
    top: -8px;
    transform: scale(0);
    transition: all 0.3s ease;
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color  !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
/*=======Mega menu css start=======*/
.page-wrapper {
  .page-header {
    .header-wrapper {
      .search-full {
        overflow: hidden;
        color: $theme-font-color;
        padding: 0;
        .form-group {
          background-color:#31322D;
          width: 845px;
          input {
            color: $theme-font-color;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0.4px;
            padding: 11px 0px 11px 25px;
            width: 100% !important;
            background-color: #F5F6F9;
            &:focus-visible {
              outline: none;
            }
            &::placeholder {
              color: $theme-font-color;
              [class="dark-only"] & {
                color: $dark-all-font-color;
              }
            }
            [class="dark-only"] & {
              background-color: $sidebar-icon;
              color: $dark-all-font-color;
            }
          }
          svg {
            stroke: $theme-font-color;
            width: 23px;
            height: 23px;
            right: 20px;
            top: 25%;
            position: absolute;
            [dir="rtl"] & {
              right: unset;
              left: 20px;
            }
            [class="dark-only"] & {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}
.page-wrapper {
  &.compact-small {
    .page-header {
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
      }
    }
  }
}
footer {
  padding: 15px 35px 15px 35px !important;
  p {
    .footer-icon {
      width: 22px;
      height: 18px;
      vertical-align: middle;
    }
  }
}
/*=======Mega menu css end=======*/
/**=====================
    5.3 Header CSS Ends
==========================**/