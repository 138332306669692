/**=====================
  2.24 Print CSS Start
==========================**/
@media print {
  .sidebar-wrapper,
  .page-header {
    display: none;
  }
  .page-body {
    margin: 0;
  }
  .page-wrapper {
    .page-title {
      visibility: hidden;
    }
    .page-body-wrapper {
      .page-title {
        display: none;
      }
    }
  }
  table.fixedHeader-floating {
    display: none;
  }
}
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}
.invoice {
  .text-right {
    input {
      margin-top: 6px;
    }
  }
  .d-flex {
    align-items: center;
  }
}
/**=====================
  2.24 Print CSS Ends
==========================**/